import Icons from '../../modules/icons';
const {
  ReactIcon,
  SassIcon,
  GatsbyIcon,
  GitHubActionsIcon,
  ReduxIcon,
  TailwindCSSIcon,
  DotNetIcon,
  TypeScriptIcon,
  LernaIcon,
  StorybookIcon,
  PythonIcon,
  FlaskIcon,
  AngularIcon,
  MSQLIcon,
  AngularMaterialIcon,
  GoogleAnalyticsIcon,
  BootstrapIcon,
  AppiumIcon,
  CSharpIcon,
  MaterialUIIcon
} = Icons;

export const WORK_EXPERIENCES = [
  {
    title: 'Software Developer',
    employer: 'Amdocs',
    period: 'Sep 2022 - Feb 2025',
    teams: [
      {
        name: 'Bell',
        technologies: [
          { Icon: DotNetIcon, label: '.NET' },
          { Icon: ReactIcon, label: 'React' },
          { Icon: ReduxIcon, label: 'Redux' },
          { Icon: CSharpIcon, label: 'C-Sharp' },
          { Icon: TypeScriptIcon, label: 'TypeScript' }
        ],
        description:
          'Worked on the Digital Customer Experience team, focusing on enhancing the billing pages of Bell.ca. Resolved UI and accessibility defects and implemented features to enable customers to easily view their bill information, including subscribed services, costs, payments, and usage.'
      },
      {
        name: 'T-Mobile',
        technologies: [
          { Icon: SassIcon, label: 'SASS' },
          { Icon: ReactIcon, label: 'React' },
          { Icon: ReduxIcon, label: 'Redux' },
          { Icon: MaterialUIIcon, label: 'Material UI' },
          { Icon: TypeScriptIcon, label: 'TypeScript' },
          { Icon: TailwindCSSIcon, label: 'Tailwind CSS' },
          { Icon: LernaIcon, label: 'Lerna' },
          { Icon: StorybookIcon, label: 'Storybook' }
        ],
        description:
          'Developed reusable & data-driven React widgets integrated with Adobe Experience Manager, enabling T-Mobile to create custom dashboard pages through drag-and-drop functionality. These widgets provided businesses using T-Mobile with critical insights, including service details, usage statistics, and alerts about security issues or outages.'
      }
    ],
    imgName: 'amdocs'
  },
  {
    title: 'Software Engineer Intern',
    employer: 'RBC',
    period: 'Sep 2021 - Dec 2021',
    technologies: [
      { Icon: MSQLIcon, label: 'MS SQL' },
      { Icon: FlaskIcon, label: 'Flask' },
      { Icon: PythonIcon, label: 'Python' },
      { Icon: AngularIcon, label: 'Angular' },
      { Icon: AngularMaterialIcon, label: 'Angular Material' }
    ],
    imgName: 'rbc',
    description:
      'Recreated a web app internal teams used to view information about servers such as which team is using it and installed programs. The redesign focused on improving performance, simplifying deployment, and enhancing the UI to provide a more user-friendly experience.'
  },
  {
    title: 'Software Engineer Intern',
    employer: 'DBRS Morningstar',
    period: 'Sep 2020 - Dec 2020',
    technologies: [
      { Icon: DotNetIcon, label: '.NET' },
      { Icon: MSQLIcon, label: 'MS SQL' },
      { Icon: CSharpIcon, label: 'C-Sharp' },
      { Icon: AngularIcon, label: 'Angular' },
      { Icon: AngularMaterialIcon, label: 'Angular Material' }
    ],
    imgName: 'dbrs',
    description:
      'Maintained and developed new features for a web app used by analysts to manage and assess company credit ratings.'
  },
  {
    title: 'Freelance Web Developer',
    employer: 'Zeera by the Bay',
    linkToEmployer: 'https://www.zeeraindiancuisine.ca',
    period: 'Mar 2020 - Apr 2020',
    technologies: [
      { Icon: SassIcon, label: 'SASS' },
      { Icon: GatsbyIcon, label: 'Gatsby' },
      { Icon: BootstrapIcon, label: 'Bootstrap' },
      { Icon: GitHubActionsIcon, label: 'GitHub Actions' },
      { Icon: GoogleAnalyticsIcon, label: 'Google Analytics' }
    ],
    imgName: 'zeera',
    description:
      'Revamped an outdated website built with GoDaddy Website Builder by transitioning to a custom-coded solution, significantly improving performance, UX, design, SEO, and accessibility.'
  },
  {
    title: 'QA Analyst Intern',
    employer: 'Clearbridge Mobile',
    period: 'Jan 2020 - Apr 2020',
    technologies: [{ Icon: AppiumIcon, label: 'Appium' }],
    imgName: 'clearbridge',
    description:
      'Performed various types of testing, including regression, ad hoc, functional, and user acceptance for mobile applications such as Purolator. Streamlined testing processing by writing automated testing scripts using Appium.'
  }
];
